import { nanoid } from "nanoid";
import Carousel from "nuka-carousel";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import cargoTructAiGen from "../images/cargo-truck-ai.png";
import cargoTruck from "../images/cargo-truck.jpg";
import ktLogo from "../images/horizontal-logo.svg";

function LoginRightSide() {
	return (
		<div
			style={{
				backgroundImage: `url(${cargoTruck})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
			className="h-100 w-100 rounded"
		/>
	);
}

function RegisterRightSide() {
	const location = useLocation();

	let carouselItems;

	if (location?.pathname?.includes("confirm")) {
		carouselItems = [
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					The United States, Mexico, and Canada have reached an agreement to
					modernize the 25-year-old NAFTA into a 21st century, high-standard
					agreement.
				</p>
			</div>,
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					The United States, Mexico, and Canada have reached an agreement that
					supports North American manufacturing and mutually beneficial trade.
				</p>
			</div>,
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					Canada and Mexico are our first and third largest exports markets for
					United States food and agricultural products, making up 28 percent of
					total food and agricultural exports in 2017. These exports support
					more than 325,000 American jobs.
				</p>
			</div>,
		];
	} else {
		carouselItems = [
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					The United States, Mexico, and Canada Agreement (USMCA) recognizes the
					fundamental role of small and medium-sized enterprises (SMEs) as
					engines of the North American economy.
				</p>
				<p>
					In fact, Mexico and Canada are the top two export destinations for
					U.S. SME goods. In 2016 (latest data available), 82,000 U.S. small and
					medium sized businesses exported $51.2 billion in goods to Canada, and
					53,000 U.S. small and medium businesses exported $76.2 billion in
					goods to Mexico.
				</p>
			</div>,
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					The new United States–Mexico–Canada Agreement (USMCA) will advance
					United States agricultural interests in the most important markets for
					American’s farmers, ranchers, and agribusinesses.
				</p>
				<p>
					This high-standard agreement opens new markets to expand United States
					food and agricultural exports and support food manufacturing and rural
					jobs.
				</p>
			</div>,
			<div key={nanoid()} className="h-100 py-3 px-4">
				<p>
					The United States, Mexico and Canada have reached an agreement to
					benefit American farmers, ranchers, and agribusinesses.
				</p>
				<p>
					While agriculture has generally performed well under NAFTA, important
					improvements in the agreement will enable food and agriculture to
					trade more fairly, and to expand exports of American agricultural
					products.
				</p>
			</div>,
		];
	}
	return (
		<div
			style={{
				backgroundImage: `url(${cargoTructAiGen})`,
				backgroundSize: "cover",
				backgroundPosition: "bottom",
			}}
			className="position-relative h-100 w-100 rounded"
		>
			<div
				style={{ height: "25%", backgroundColor: "rgba(0,0,0,.2)" }}
				className="position-absolute w-100 bottom-0 text-light-0"
			>
				<Carousel className="registration-carousel-frame">
					{carouselItems}
				</Carousel>
			</div>
		</div>
	);
}

function AuthLayout() {
	const location = useLocation();

	let rightSide;

	if (
		location?.pathname?.includes("login") ||
		location.pathname.includes("forgot-password")
	) {
		rightSide = <LoginRightSide />;
	} else if (
		location &&
		(location.pathname.includes("register") ||
			location.pathname.includes("complete-profile"))
	) {
		rightSide = <RegisterRightSide />;
	}
	return (
		<div
			style={{
				gridTemplateColumns: "40% 60%",
				gridTemplateRows: "max-content",
			}}
			className="d-grid"
		>
			<div style={{ height: "max-content" }} className="pt-3 px-5">
				<div style={{ marginBottom: "6rem" }}>
					<a href="https://kendricktrade.com">
						<img
							style={{ width: "10rem" }}
							src={ktLogo}
							alt="Kendrick Trade logo"
						/>
					</a>
				</div>
				<Outlet />
			</div>
			<div className="w-100 min-vh-100 p-1">{rightSide}</div>
		</div>
	);
}

export default AuthLayout;
