import React from "react";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { FINISHED_GOOD_QUESTIONS_STEP } from "../constants";
import { FinishedGoodQuestionsContextProvider } from "./FinishedGoodQuestionsContext";
import FinishedGoodQuestionsForm from "./FinishedGoodQuestionsForm";

function FinishedGoodQuestions() {
  return (
    <FinishedGoodQuestionsContextProvider>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="mb-5 d-flex w-100">
              <ProgressBar step={FINISHED_GOOD_QUESTIONS_STEP} />
            </div>

            <div className="mb-5">
              <h1 className="mb-3">Answer Finished Good Questions</h1>
              <p>
                Answer the following questions about your finished good.<br></br> Some questions may be repetitive; this is because we are asking all qualification
                questions up front for various rules.
              </p>
            </div>
            <div>
              <h2 className="mb-4">Questions</h2>
              <FinishedGoodQuestionsForm />
            </div>
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
    </FinishedGoodQuestionsContextProvider>
  );
}

export default FinishedGoodQuestions;
