import api from "./apiSlice";

const certificatesApi = api.injectEndpoints({
  endpoints(build) {
    return {
      createCertificate: build.mutation({
        query(body) {
          return {
            url: "/extras/api/v1/boms/certificate-of-origin",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [{ type: "CertificateOfOrigin", id: "LIST" }],
      }),
      getCertificate: build.query({
        query({ certificateId }) {
          return `/extras/api/v1/boms/certificate-of-origin/${certificateId}`;
        },
        providesTags: (result, error, { certificateId }) => (result ? [{ type: "CertificateOfOrigin", id: certificateId }] : []),
      }),
      listCertificates: build.query({
        query() {
          return "/extras/api/v1/boms/certificate-of-origin";
        },
        providesTags: [{ type: "CertificateOfOrigin", id: "LIST" }],
      }),
      deleteCertificate: build.mutation({
        query({ certificateId }) {
          return {
            url: `/extras/api/v1/boms/certificate-of-origin/${certificateId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, { certificateId }) => [
          { type: "CertificateOfOrigin", id: certificateId },
          { type: "CertificateOfOrigin", id: "LIST" },
        ],
      }),
    };
  },
});

export const { useCreateCertificateMutation, useGetCertificateQuery, useListCertificatesQuery, useDeleteCertificateMutation } = certificatesApi;
